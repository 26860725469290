import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { RiDragMove2Fill } from 'react-icons/ri';
import { Box, Card, CardBody, CardHeader, Flex, Input, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text } from '@chakra-ui/react';
import { ValuationInteractionConfigurationT } from '../../model/Interaction.ts';
import { saveValuationEntry, ValuationEntry } from '../../service/EntryService.ts';
import { InteractionWrapper } from '../InteractionWrapper';
import { Masonry } from 'react-plock';
import { InteractionProps } from '../Interaction/Interaction.tsx';
import { hasSubmission, recordSubmission } from '../../service/InteractionSubmissionTracker.ts';
import { translate } from '../../../../translate';

interface FormValues {
	entries: Record<string, string>;
}

export const ValuationInteraction: React.FC<InteractionProps> = ({ presentationId, interaction, onInteractionEnd }) => {
	const config = interaction.configuration as ValuationInteractionConfigurationT;
	const [error, setError] = useState<string | undefined>();
	const alreadySubmitted = hasSubmission(interaction.id);
	const defaultValues = config.statements.reduce(
		(acc, value) => {
			acc[value.id] = '';
			return acc;
		},
		{} as Record<string, string>
	);
	const {
		handleSubmit,
		formState: { isSubmitting },
		setValue,
		watch,
		register,
	} = useForm<FormValues>({
		defaultValues: {
			entries: defaultValues,
		},
	});

	const onSubmit = async (data: FormValues) => {
		const actualEntries: ValuationEntry[] = Object.entries(data.entries)
			//Filter all empty values
			.filter(([key, entry]) => {
				return key.trim() !== '' && entry !== undefined && entry.trim() !== '';
			})
			// Given the key which is the id get the actual statement from the config
			.map(([key, entry]) => {
				const parsedKey = parseInt(key, 10);
				const foundStatement = config.statements.find((statement) => statement.id === parsedKey);
				return {
					key: foundStatement!.statement, // Forcing uncheck given that we will always find a statement
					entry: parseInt(entry, 10), // Convert string to number
				};
			})
			// However in the strange case that a statement was not found, we filter it
			.filter((value) => !isEmpty(value.key));

		if (isEmpty(actualEntries) && interaction.required && !alreadySubmitted) {
			setError(translate('Add one response'));
		} else if (!isEmpty(actualEntries)) {
			await saveValuationEntry(presentationId, interaction.id, actualEntries);
			recordSubmission(interaction.id);
			onInteractionEnd();
		} else if (alreadySubmitted || !interaction.required) {
			onInteractionEnd();
		}
	};

	return (
		<InteractionWrapper interaction={interaction} onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting}>
			{!isEmpty(error) ? (
				<Text color='red' textAlign='center'>
					{error}
				</Text>
			) : undefined}
			<Box my={2} mx={{ base: 8, sm: 8, md: 0 }}>
				<Masonry
					items={config.statements || []}
					config={{
						columns: [1, 2, 3],
						gap: [12, 14, 15],
						media: [640, 768, 1024],
					}}
					render={(field) => (
						<Card key={field.id} mb={2} boxShadow='lg'>
							<CardHeader>
								<Flex alignItems='center' justifyContent='space-between' gap={5} mx={2}>
									<Flex width='70%' minWidth={0}>
										<Text fontWeight='bold'>{field.statement}</Text>
									</Flex>
									<Flex width='20%'>
										<Input
											type='number'
											// required={config.required}
											min={config.minValue}
											max={config.maxValue}
											placeholder={String(config.minValue)}
											variant='flushed'
											autoComplete='off'
											{...register(`entries.${field.id}`, {
												min: config.minValue,
												max: config.maxValue,
												// required: config.required,
											})}
											_focusVisible={{
												borderColor: field.color,
												boxShadow: `0px 1px 0px 0px ${field.color}`,
											}}
										/>
									</Flex>
								</Flex>
							</CardHeader>
							<CardBody width='85%' alignSelf='center'>
								<Slider
									value={watch(`entries.${field.id}`) !== undefined ? Number(watch(`entries.${field.id}`)) : 0}
									onChange={(value) =>
										setValue(`entries.${field.id}`, '' + value, {
											shouldDirty: true,
											shouldTouch: true,
										})
									}
									min={config.minValue}
									max={config.maxValue}
									focusThumbOnChange={false}
								>
									<SliderTrack bg={field.color ? field.color : 'brand'}>
										<SliderFilledTrack bg={field.color ? field.color : 'brand'} />
									</SliderTrack>
									<SliderThumb boxSize={8} zIndex={0}>
										<Box color={field.color ? field.color : 'brand'} as={RiDragMove2Fill} />
									</SliderThumb>
								</Slider>
							</CardBody>
						</Card>
					)}
				/>
			</Box>
		</InteractionWrapper>
	);
};
