import { useEffect, useRef } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useSliderControls } from '../SliderControlsProvider/SliderControlsProvider.tsx';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { translate } from '../../../../translate';

interface Props {
	onGoForward?: () => void; //Override default goForward behaviour
}

export const SlideArrows = ({ onGoForward }: Props) => {
	const slider = useSliderControls();
	const buttonLeftRef = useRef<HTMLButtonElement>(null);
	const buttonRightRef = useRef<HTMLButtonElement>(null);

	const handleOnGoForward = () => {
		if (onGoForward) {
			onGoForward();
		} else {
			slider.goForward();
		}
	};

	useEffect(() => {
		const handleMoveBackward = (event: KeyboardEvent) => {
			if (event.key === 'ArrowLeft' && buttonLeftRef.current) {
				// buttonLeftRef.current.click();
			}
		};
		const handleMoveForward = (event: KeyboardEvent) => {
			if (event.key === 'ArrowRight' && buttonRightRef.current) {
				// buttonRightRef.current.click();
			}
		};

		// Attach the event listener when the component mounts
		window.addEventListener('keydown', handleMoveBackward);
		window.addEventListener('keydown', handleMoveForward);

		// Clean up the event listener when the component unmounts
		return () => {
			window.removeEventListener('keydown', handleMoveBackward);
			window.removeEventListener('keydown', handleMoveForward);
		};
	}, []);

	if (!slider.canGoBack && !slider.canGoForward) {
		return null;
	}

	return (
		<>
			<Box position='fixed' left={0} bottom={0} width='100%' zIndex={1} backgroundColor='highlight'>
				<Flex justify='center' gap={4} my={2}>
					{!slider.presentation.hideControls && (
						<Box>
							<Button leftIcon={<MdChevronLeft size='1.6rem' />} ref={buttonLeftRef} variant='submit' onClick={slider.goBack} isDisabled={!slider.canGoBack} m={0}>
								<Text display={['none', 'block']}>{translate('Prev')}</Text>
							</Button>
							{/*<Icon fontSize={40} padding={2} as={BsArrowLeft} color='buttonTextColor' borderRadius='full' backgroundColor='buttonColor' />*/}
						</Box>
					)}
					{/*{!slider.presentation.hideControls && slider.canGoBack && (*/}
					{/*	<Box>*/}
					{/*		<Button leftIcon={<MdChevronLeft />} isLoading={isSubmitting} variant='submit' onClick={slider.goBack} m={0}>*/}
					{/*			{translate('Prev')}*/}
					{/*		</Button>*/}
					{/*		/!*<Icon fontSize={40} padding={2} as={BsArrowLeft} color='buttonTextColor' borderRadius='full' backgroundColor='buttonColor' />*!/*/}
					{/*	</Box>*/}
					{/*)}*/}

					{/*{onSubmit !== undefined ? (*/}
					{/*	<Flex justifyContent='center'>*/}
					{/*		<Button rightIcon={<MdCheck />} isLoading={isSubmitting} variant='submit' type='submit' onSubmit={onSubmit} m={0}>*/}
					{/*			{translate('Submit')}*/}
					{/*		</Button>*/}
					{/*	</Flex>*/}
					{/*) : null}*/}

					{!slider.presentation.hideControls && (
						<Box>
							<Button rightIcon={<MdChevronRight size='1.6rem' />} ref={buttonRightRef} variant='submit' onClick={handleOnGoForward} isDisabled={!slider.canGoForward} m={0}>
								<Text display={['none', 'block']}>{translate('Next')}</Text>
							</Button>
						</Box>
					)}
					{/*{!slider.presentation.hideControls && slider.canGoForward && (*/}
					{/*	<Box>*/}
					{/*		<Button rightIcon={<MdChevronRight />} isLoading={isSubmitting} variant='submit' onClick={handleOnGoForward} m={0}>*/}
					{/*			{translate('Next')}*/}
					{/*		</Button>*/}
					{/*	</Box>*/}
					{/*)}*/}
				</Flex>
			</Box>

			{/* Navigation Arrows */}
			{/*{!slider.presentation.hideControls ? (*/}
			{/*	<>*/}
			{/*		<Box position='absolute' left={0} top='50%' transform='translateY(-50%)' padding='0 10px' cursor={slider.canGoBack ? 'pointer' : 'default'} onClick={slider.goBack}>*/}
			{/*			{slider.canGoBack && <Icon fontSize={40} padding={2} as={BsArrowLeft} color='buttonTextColor' borderRadius='full' backgroundColor='buttonColor' />}*/}
			{/*		</Box>*/}
			{/*		<Box*/}
			{/*			position='absolute'*/}
			{/*			right={0}*/}
			{/*			top='50%'*/}
			{/*			transform='translateY(-50%)'*/}
			{/*			padding='0 10px'*/}
			{/*			cursor={slider.canGoForward ? 'pointer' : 'default'}*/}
			{/*			onClick={handleOnGoForward}*/}
			{/*		>*/}
			{/*			{slider.canGoForward && <Icon fontSize={40} padding={2} as={BsArrowRight} color='buttonTextColor' borderRadius='full' backgroundColor='buttonColor' />}*/}
			{/*		</Box>*/}
			{/*	</>*/}
			{/*) : null}*/}
		</>
	);
};
