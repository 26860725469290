export const translations = {
	en: {
		translation: {
			Submit: undefined,
			'It seems there are no answers to this question yet.': undefined,
			'Add a title to your drawing': undefined,
			'Add a description': undefined,
			'Select image': undefined,
			'Enter a link': undefined,
			'Field too short': undefined,
			'Field too long': undefined,
			'Field required': 'Required',
			'Add one response': undefined,
			'Draw something': undefined,
			'Please enter a valid image url': undefined,
			Prev: undefined,
			Next: undefined,
		},
	},
	es: {
		translation: {
			Submit: 'Enviar',
			'It seems there are no answers to this question yet.': 'Parece que no hay respuestas a esta pregunta todavía.',
			'Add a title to your drawing': 'Añade título a tu dibujo',
			'Add a description': 'Añade una descripción',
			'Select image': 'Selecciona una imágen',
			'Enter a link': 'Ingresa un link',
			'Field too short': 'Pocos caracteres',
			'Field too long': 'Muchos caracteres',
			'Field required': 'Requerido',
			'Add one response': 'Agrega una respuesta',
			'Draw something': 'Agrega un dibujo',
			'Please enter a valid image url': 'Ingresa una url válida',
			Prev: 'Anterior',
			Next: 'Siguiente',
		},
	},
} as const;
