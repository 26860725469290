import React, { useState } from 'react';
import { Loading } from '../../../commons/components/Loading';
import { Card, CardBody, Circle, Flex, Heading, Image, SimpleGrid, Text } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { motion, Variants } from 'framer-motion';
import { BasicCardDataMedia, CardaDataMediaType, CardData } from '../../service/VisualizationService.ts';
import { useCardsData } from '../../hooks/useCardsData.ts';
import { VisualizationWrapper } from '../VisualizationWrapper';
import { VisualizationProps } from '../Visualization/Visualization.tsx';
import { BasicCardsVisualizationT } from '../../model/Visualization.ts';

export const BasicFlipCardsVisualization: React.FC<VisualizationProps> = ({ presentationId, visualization }) => {
	const conf = visualization.configuration as BasicCardsVisualizationT;
	const { data, isLoading } = useCardsData(presentationId, visualization.id);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<VisualizationWrapper visualization={visualization} isNoData={!isLoading && isEmpty(data)}>
			<SimpleGrid
				alignSelf='center'
				columns={{
					base: 1,
					md: 2,
					xl: conf.maxColumns,
				}}
				justifyItems='center'
				gap={2}
				mx={16}
				my={8}
			>
				{data?.map((entry) => <ImageFlipCard key={entry.title} entry={entry} />)}
			</SimpleGrid>
		</VisualizationWrapper>
	);
};

interface ImageFlipCardProps {
	entry: CardData;
}

const variants: Variants = {
	front: { rotateY: 0 },
	back: { rotateY: 180 },
};

const ImageFlipCard = ({ entry }: ImageFlipCardProps) => {
	const emptyDescription = isEmpty(entry.description);
	const [isFlipped, setIsFlipped] = useState(false);

	const media = entry.media as BasicCardDataMedia;
	const handleOnClick = () => {
		setIsFlipped(!isFlipped);
	};

	const renderFlipped = () => (
		<CardBody as={Flex} id='flipped-body' overflowY='auto' transform='rotateY(-180deg)'>
			{!emptyDescription ? (
				<Text textAlign='center' wordBreak='break-word' fontSize='lg' margin='auto'>
					{entry.description}
				</Text>
			) : (
				<Heading wordBreak='break-word' my={2} size='xs' textAlign='center'>
					{entry.title}
				</Heading>
			)}
		</CardBody>
	);

	const renderNotFlipped = () => (
		<CardBody flex='' overflowY='auto' textAlign='center'>
			<Flex p={4}>
				{media.mediaType === CardaDataMediaType.IMAGE ? (
					<Image src={media.value} alt={entry.title} objectFit='cover' borderRadius='2xl' objectPosition='center' w='100%' h='100%' />
				) : null}
				{media.mediaType === CardaDataMediaType.ICON ? (
					<Circle size={emptyDescription ? 24 : 16} bgColor='highlight' mr={2}>
						<Text fontSize={emptyDescription ? '6xl' : '4xl'}>{media.value}</Text>
					</Circle>
				) : null}
				{media.mediaType === CardaDataMediaType.DRAWING ? (
					<div style={{ width: '30' }}>
						<img src={`data:image/svg+xml;base64,${btoa(decodeURIComponent(encodeURIComponent(media.value)))}`} alt='SVG Content' style={{ width: '100%', height: '100%' }} />
					</div>
				) : null}
			</Flex>
			{!emptyDescription && (
				<Flex>
					<Heading wordBreak='break-word' size='xs' textAlign='center' my={0}>
						{entry.title}
					</Heading>
				</Flex>
			)}
		</CardBody>
	);

	return (
		<motion.div
			animate={!isFlipped ? 'front' : 'back'}
			onClick={handleOnClick}
			variants={variants}
			transition={{
				duration: 1 / 2,
			}}
			whileHover={{
				scale: 1.07,
				transition: { duration: 1 / 5 },
				zIndex: 1,
			}}
			style={{ margin: '0.5rem' }}
		>
			<Card id='card' onClick={handleOnClick} cursor='pointer' justifyContent='center' alignItems='center' boxShadow='md' _hover={{ boxShadow: 'lg' }} w='64' h='64'>
				{isFlipped ? renderFlipped() : renderNotFlipped()}
			</Card>
		</motion.div>
	);
};

BasicFlipCardsVisualization.defaultProps = {};
