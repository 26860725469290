import React, { ChangeEvent, useState } from 'react';
import { Button, FormControl, FormErrorMessage, Image, Input, VStack } from '@chakra-ui/react';
import { translate } from '../../../../../../translate';

interface Props {
	onChange: (selectedImageUrl: string) => void;
}

export const UrlImagePicker: React.FC<Props> = ({ onChange }) => {
	const [imageUrl, setImageUrl] = useState('');
	const [error, setError] = useState('');

	const isValidImageUrl = (url: string) => {
		return /^https?:\/\/.+\.(jpg|jpeg|png|webp|avif|gif|svg)(\?.*)?$/.test(url.toLowerCase());
	};

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const url = e.target.value;
		setImageUrl(url);

		if (isValidImageUrl(url)) {
			setError('');
		} else {
			setError(translate('Please enter a valid image url'));
		}
	};

	const handleSelectClick = () => {
		if (isValidImageUrl(imageUrl)) {
			onChange(imageUrl);
		} else {
			setError(translate('Please enter a valid image url'));
		}
	};

	return (
		<VStack>
			<FormControl isInvalid={!!error} m={4}>
				<Input placeholder='Type image link' autoComplete='off' value={imageUrl} onChange={handleInputChange} isInvalid={!!error} />
				{error && <FormErrorMessage>{error}</FormErrorMessage>}
			</FormControl>
			{isValidImageUrl(imageUrl) && <Image src={imageUrl} alt='Preview' boxSize='md' fit='contain' />}
			<Button variant='submit' isDisabled={!isValidImageUrl(imageUrl)} onClick={handleSelectClick}>
				Select
			</Button>
		</VStack>
	);
};
UrlImagePicker.defaultProps = {};
