import React, { useEffect, useState } from 'react';
import { Box, FormControl, FormErrorMessage, Input, Text, Textarea, VStack } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { DrawingInteractionConfigurationT } from '../../model/Interaction.ts';
import { usePreviewPageInfo } from '../../../../pages/PreviewPage/PreviewPage.tsx';
import { saveDrawingEntry } from '../../service/EntryService.ts';
import { InteractionWrapper } from '../InteractionWrapper';
import { Excalidraw, exportToSvg } from '@excalidraw/excalidraw';
import { ExcalidrawImperativeAPI } from '@excalidraw/excalidraw/types/types';
import { ImportedDataState } from '@excalidraw/excalidraw/types/data/types';
import { InteractionContainer } from '../InteractionContainer';
import './excalidraw.css';
import { InteractionProps } from '../Interaction/Interaction.tsx';
import { translate } from '../../../../translate';
import { validateMaxLength, validateMinLength, validateRequired } from '../../validation/InteractionValidation.ts';
import { hasSubmission, recordSubmission } from '../../service/InteractionSubmissionTracker.ts';

interface FormValueType {
	heading: string;
	text: string;
}

const initialData: ImportedDataState = {
	appState: {
		activeTool: {
			type: 'freedraw',
			lastActiveTool: null,
			locked: true,
			customType: null,
		},
		currentItemStrokeWidth: 0.8,
		width: 200,
		height: 200,
	},
};

export const DrawingInteraction: React.FC<InteractionProps> = ({ presentationId, interaction, onInteractionEnd }) => {
	const config = interaction.configuration as DrawingInteractionConfigurationT;
	const alreadySubmitted = hasSubmission(interaction.id);
	const [excalidrawAPI, setExcalidrawAPI] = useState<ExcalidrawImperativeAPI | null>(null);
	const [error, setError] = useState<string | undefined>();
	const {
		register,
		handleSubmit,
		formState: { isSubmitting, errors },
		setFocus,
	} = useForm<FormValueType>({
		defaultValues: {
			heading: '',
			text: '',
		},
	});

	const { shouldAutoFocus } = usePreviewPageInfo();
	useEffect(() => {
		if (shouldAutoFocus) {
			setFocus('heading');
		}
	}, [setFocus, shouldAutoFocus]);

	const excalidrawElements = () => {
		if (!excalidrawAPI) {
			return [];
		}
		return excalidrawAPI.getSceneElements();
	};

	const onSubmit: SubmitHandler<FormValueType> = async (data) => {
		const elements = excalidrawElements();

		if (isEmpty(elements) && !isEmpty(data.heading) && !alreadySubmitted) {
			setError(translate('Draw something'));
		} else if (!isEmpty(data.heading) && !isEmpty(elements)) {
			const svgExport = await exportToSvg({
				elements,
				appState: {
					...initialData.appState,
					exportWithDarkMode: false,
				},
				files: excalidrawAPI!.getFiles(),
			});
			await saveDrawingEntry(presentationId, interaction.id, {
				text: data.text,
				heading: data.heading,
				drawing: svgExport.outerHTML || '',
			});
			recordSubmission(interaction.id);
			onInteractionEnd();
		} else if (alreadySubmitted || !interaction.required) {
			onInteractionEnd();
		}
	};

	const renderInformationInputs = () => {
		return (
			<VStack pt={2} align='stretch' w='100%'>
				<FormControl isInvalid={!isEmpty(errors.heading)}>
					<Input
						placeholder={translate('Add a title to your drawing')}
						size='lg'
						maxLength={500}
						autoComplete='off'
						{...register('heading', {
							validate: {
								required: (value) => {
									return (interaction.required && !alreadySubmitted) || !isEmpty(excalidrawElements()) ? validateRequired(value, translate('Field required')) : undefined;
								},
								minLength: (value) => {
									return validateMinLength(value, 3, translate('Field too short'));
								},
								maxLength: (value) => {
									return validateMaxLength(value, 500, translate('Field too long'));
								},
							},
						})}
					/>
					{errors.heading && <FormErrorMessage>{errors.heading.message}</FormErrorMessage>}
				</FormControl>
				{config.longAnswer ? (
					<FormControl isInvalid={!isEmpty(errors.text)}>
						<Textarea
							placeholder={translate('Add a description')}
							rows={3}
							maxLength={2400}
							{...register('text', {
								validate: {
									minLength: (value) => {
										return validateMinLength(value, 3, translate('Field too short'));
									},
									maxLength: (value) => {
										return validateMaxLength(value, 500, translate('Field too long'));
									},
								},
							})}
						/>
						{errors.text && <FormErrorMessage>{errors.text.message}</FormErrorMessage>}
					</FormControl>
				) : null}
			</VStack>
		);
	};

	return (
		<InteractionWrapper interaction={interaction} onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting}>
			<InteractionContainer>{renderInformationInputs()}</InteractionContainer>
			{!isEmpty(error) ? (
				<Text color='red' textAlign='center' my={4}>
					{error}
				</Text>
			) : undefined}
			<Box
				height='500px'
				overflowX='auto'
				my={4}
				borderRadius='3xl'
				border={!isEmpty(error) ? '3px solid' : undefined}
				borderColor={!isEmpty(error) ? 'red' : undefined}
				mx={{ base: 6, md: 0 }}
			>
				<Excalidraw
					excalidrawAPI={(api: ExcalidrawImperativeAPI) => setExcalidrawAPI(api)}
					initialData={initialData}
					UIOptions={{
						canvasActions: {
							changeViewBackgroundColor: false,
							export: false,
							loadScene: false,
							saveToActiveFile: false,
							toggleTheme: false,
							saveAsImage: false,
						},
						tools: {
							image: false,
						},
					}}
				/>
			</Box>
		</InteractionWrapper>
	);
};

DrawingInteraction.defaultProps = {};
