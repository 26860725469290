import React from 'react';
import { StaticContentT, StaticContentTypeT } from '../../model/StaticContent.ts';
import { StaticContentHtml } from '../StaticContentHtml';
import { SlideArrows } from '../../../presentation/components/SlideArrows';

export interface StaticContentProps {
	presentationId: string;
	staticContent: StaticContentT;
}

export const StaticContent: React.FC<StaticContentProps> = (props) => {
	if (props.staticContent.type === StaticContentTypeT.HTML) {
		return (
			<>
				<StaticContentHtml key={props.staticContent.id} {...props} />
				<SlideArrows />
			</>
		);
	}
	return null;
};
